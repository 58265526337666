<template>
  <v-row>
    <v-col cols="6">
      <div class="text-subtitle-1">Parts: ${{partsTotalCost}}</div>
      <div
        class="text-body-2"
        v-for="partCount in product.parts"
        :key="partCount.id"
      >
        ({{ partCount.quantity * quantity }}) {{ partCount.part.Name }} @ ${{
          Number.parseFloat(partCount.part.materialCost).toFixed(2)
        }}
      </div>
      <div>{{product.package.name}} @ ${{Number.parseFloat(product.package.cost).toFixed(2)}}</div>
    </v-col>
    <v-col cols="6">
      <div class="text-subtitle-1">Raw Materials</div>
      <div class="text-body-2" v-for="rm in rawMaterialsTotals" :key="rm.id">
        {{ rm.name }}: {{ rm.qty }} units ${{rm.cost}}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import strapiApi from "../mixins/strapiApi";
const sum = (arr) =>
  arr.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
export default {
  name: "ProductTableDetail",
  mixins: [strapiApi],
  props: { product: Object, qty: Number },
  data: () => ({
    quantity: 1,
  }),
  watch: {
    async product() {
      await this.getParts();
    },
  },
  computed: {
    rawMaterials() {
      return this.product.parts.flatMap((part) => {
        let arr = new Array(part.quantity);
        if (!part.part.rawMaterial) return arr;
        arr.fill(
          part.part.rawMaterial.flatMap((rm) => {
            return {
              name: rm.raw_material.Name,
              qty: rm.Quantity,
              cost: rm.raw_material.cost
            };
          })
        );
        return arr.flat();
      });
    },
    rawMaterialsTotals() {
      const matNames = Array.from(
        new Set(this.rawMaterials.map((rm) => rm.name))
      );
      return matNames.map((name) => {
        return {
          name: name,
          qty: sum(
            this.rawMaterials
              .filter((rm) => rm.name == name)
              .map((rm) => rm.qty * this.quantity)
          ),
          cost: sum(
            this.rawMaterials.filter((rm) => rm.name == name)
              .map((rm) => rm.qty * rm.cost * this.quantity)
          )
        };
      });
    },
    partsTotalCost() {
      let sum = 0
      this.product.parts.map((partCount) => sum += Number.parseFloat(partCount.part.materialCost * partCount.quantity))
      sum += Number.parseFloat(this.product.package.cost)
      return sum.toFixed(2)
    }

  },
  methods: {
    async getPart(id) {
      return this.strapiGet(`parts/${id}`);
    },
    async getParts() {
      this.product.parts = await Promise.all(
        this.product.parts.map(async (partCount) => {
          partCount.part = await this.getPart(partCount.part.id);
          return partCount;
        })
      );
    },
  },
  async mounted() {
    if (this.qty) this.quantity = this.qty;
    await this.getParts();
    this.$emit("rm-total-updated", this.rawMaterialsTotals);
  },
};
</script>

<style>
</style>