<template>
  <v-container>
    <v-row class="text-h5">
      <v-col cols="10"> Stats </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>{{ orders.length }} open orders</div>
        <div>{{ owedItems }} items owed</div>
        <div>${{ owedShippingTotal }} shipping owed</div>
        <div>${{ owedValue }} unfulfilled value</div>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="collection in owedCollections"
          :key="collection.id"
        >
          <v-expansion-panel-header
            @click="collectionRmTotal = []"
            class="text-subtitle-1"
          >
            {{ collection.name }} - {{ getCollectionOwedTotal(collection) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-col cols="6">
              <div class="text-subtitle-1">Raw Materials</div>
              <div
                class="text-body-2"
                v-for="rm in summedRmTotals"
                :key="rm.name"
              >
                {{ rm.name }}: {{ Math.round(rm.qty) }} sqft
              </div>
            </v-col>
            <v-card
              flat
              outlined
              v-for="(product, index) in getCollectionOwedProducts(collection)"
              :key="index"
              style="margin-bottom: 16px"
            >
              <v-card-title class="text-subtitle-1">
                {{ product.title }} - {{ getProductOwedTotal(product) }}
              </v-card-title>
              <v-card-text>
                <product-table-detail
                  :product="getStrapiProduct(product.id)"
                  :qty="getProductOwedTotal(product)"
                  v-on:rm-total-updated="updateRmTotal"
                />
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="owedDoors.length > 0">
          <v-expansion-panel-header>
            Storage Cube Doors
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-for="(owedDoor, index) in owedDoors" :key="index">
              {{ owedDoor.color }} - {{ owedDoor.count }}
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import ProductTableDetail from "../components/ProductTableDetail.vue";
import strapiApi from "../mixins/strapiApi";
const sum = (arr) =>
  arr.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
export default {
  name: "StatsView",
  mixins: [strapiApi],
  components: {
    ProductTableDetail,
  },
  data: () => ({
    loading: true,
    loadingText: "",
    noDataText: "",
    orders: [],
    collections: [],
    products: [],
    collectionRmTotal: [],
  }),
  async mounted() {
    try {
      this.products = await this.getProducts("");
      this.orders = await this.getOrders("closedAt_null=true&_limit=-1");
      this.collections = await this.getCollections();
      this.collections = this.collections.map((c) => {
        c.products = c.products.map((p) => p.shopifyId);
        return c;
      });
      this.loadingText = "Getting strapi products";
    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  },
  computed: {
    summedRmTotals() {
      const flat = this.collectionRmTotal.flat();
      const materialNames = Array.from(new Set(flat.map((m) => m.name)));
      return materialNames.map((mn) => {
        return {
          name: mn,
          qty: sum(
            flat
              .filter((rmCount) => rmCount.name == mn)
              .map((rmCount) => rmCount.qty)
          ),
        };
      });
    },
    lineItems() {
      return this.orders.flatMap((o) => {
        return o.shopifyJson.line_items;
      });
    },
    owedItems() {
      return sum(this.lineItems.map((li) => li.fulfillable_quantity));
    },
    owedProducts() {
      return Array.from(
        new Set(
          this.lineItems.map((li) => {
            return {
              id: li.product_id,
              title: li.title,
            };
          })
        )
      );
    },
    owedCollections() {
      return this.collections.filter((c) => {
        return c.products.some((cp) => {
          return this.owedProducts.some((p) => {
            return p.id == cp;
          });
        });
      });
    },
    owedShippingLines() {
      return this.orders.flatMap((o) => o.shopifyJson.shipping_lines);
    },
    owedShippingTotal() {
      return sum(this.owedShippingLines.map((l) => parseFloat(l.price))).toFixed(0);
    },
    owedValue(){
      return sum(this.lineItems.map(l => parseFloat(l.price)*l.fulfillable_quantity)).toFixed(0);
    },
    owedDoors() {
      const cubeCollection = this.collections.find((c) => {
        return c.name == "Storage Cube";
      });
      if (!cubeCollection) return [];
      const owedProducts = this.getCollectionOwedProducts(cubeCollection);
      const owedLines = this.lineItems.filter((li) => {
        return owedProducts.flatMap((op) => op.id).includes(li.product_id);
      });
      const owedDoors = owedLines.flatMap((ol) => {
        const color = ol.variant_title.slice(
          0,
          ol.variant_title.indexOf("/") - 1
        );
        return Array(ol.fulfillable_quantity).fill(color);
      });
      const doorTypes = Array.from(new Set(owedDoors));
      return doorTypes.map((dt) => {
        return {
          color: dt,
          count: owedDoors.filter((od) => od == dt).length,
        };
      });
    },
  },
  methods: {
    updateRmTotal(val) {
      this.collectionRmTotal.push(val);
    },
    getStrapiProduct(id) {
      return this.products.find((p) => p.shopifyId == id);
    },
    getCollectionOwedProducts(collection) {
      return collection.products
        .filter((cp) => {
          return this.owedProducts.some((p) => {
            return p.id == cp;
          });
        })
        .map((cp) => {
          return this.owedProducts.find((p) => {
            return p.id == cp;
          });
        });
    },
    getProductOwedTotal(p) {
      const quantities = this.lineItems
        .filter((li) => {
          return li.product_id == p.id;
        })
        .map((li) => li.fulfillable_quantity);
      return sum(quantities);
    },
    getCollectionOwedTotal(collection) {
      const quantities = this.lineItems
        .filter((li) => {
          return collection.products.some((p) => {
            return li.product_id == p;
          });
        })
        .map((li) => li.fulfillable_quantity);
      return sum(quantities);
    },
  },
};
</script>
<style>
.order-detail-td {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-top-style: none;
  border-width: thin;
}
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #f5f5f5;
}
</style>
